import clsx from 'clsx';

interface PanelProps {
  panelId: string;
  panelContent: JSX.Element | string;
  headingId: string;
  isExpanded: boolean;
}

export default function Panel({ panelId, panelContent, headingId, isExpanded }: PanelProps): JSX.Element {
  return (
    <div
      className={clsx('grid overflow-hidden border-b border-grey-3 transition-all duration-300 ease-in-out', {
        'grid-rows-[0fr]': !isExpanded,
        'grid-rows-[1fr] pb-4': isExpanded,
      })}
      data-testid="panel"
      role="region"
      id={panelId}
      aria-labelledby={headingId}
    >
      <div className="overflow-hidden">{panelContent}</div>
    </div>
  );
}
