import { FNCProduct } from 'shared/types/product/Product';
import TableItem from './table-item';

interface SpecsTableProps {
  product: FNCProduct;
}

export default function SpecificationTable({ product }: SpecsTableProps): JSX.Element {
  const bundles = product.bundles && product.bundles.length > 0 ? product.bundles : [{ product }];

  return (
    <div className="grid grid-cols-1 gap-x-7 gap-y-10 lg:grid-cols-3">
      {bundles.map((bundle) => (
        <TableItem key={bundle.product.productId} product={bundle.product} />
      ))}
    </div>
  );
}
