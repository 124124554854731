import { useState } from 'react';
import clsx from 'clsx';
import { MdChevronRight } from 'react-icons/md';
import { ShippingMethod as ShippingMethodType } from 'shared/types/cart';
import CalendarManager from '~/components/furniturechoice/calendar-manager';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import { useFormat } from '~/helpers/hooks/useFormat';

interface PostcodeDeliveryCalendarProps {
  shippingMethod: ShippingMethodType;
  onDeliveryDateChange(date: Date | null): void;
  defaultValue?: string;
}

export default function PostcodeDeliveryCalendar({ shippingMethod }: PostcodeDeliveryCalendarProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [isValidPostcode, setIsValidPostcode] = useState(false);
  const [postcode, setPostcode] = useState('');
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const clearCalendar = () => {
    setIsValidPostcode(false);
  };

  const checkPostcode = (e: React.FormEvent) => {
    e.preventDefault();
    setIsValidPostcode(true);
  };

  return (
    <section className="my-6">
      <div className="mb-4 flex text-14">
        <button className="flex text-14 font-semibold" onClick={toggleVisibility}>
          <span>{formatMessage({ id: 'tastics.product.postcode-delivery-calendar.form_submit_button' })}</span>
        </button>
        {isVisible && isValidPostcode ? (
          <span>
            :{' '}
            <button className="font-normal underline" onClick={clearCalendar}>
              {postcode}
            </button>
          </span>
        ) : (
          <MdChevronRight className="text-16" />
        )}
      </div>

      {isVisible && (
        <div>
          {!isValidPostcode && (
            <form className="flex flex-row" onSubmit={checkPostcode}>
              <FNCInput
                className="mb-2 w-full [&>[role=alert]]:text-red-1 [&_input]:rounded-e-none"
                label="Enter postcode"
                name=""
                type="text"
                required
                onValidChange={(e) => setPostcode(e.target.value)}
              />
              <button
                className={clsx(
                  'surface-green-2 mb-1 h-[58px] w-auto rounded rounded-s-none px-6 py-3 text-center text-14 font-semibold leading-5 text-white',
                )}
                type="submit"
              >
                Apply
              </button>
            </form>
          )}
          {isValidPostcode && (
            <section className="my-2">
              <CalendarManager
                shippingMethod={shippingMethod}
                defaultValue=""
                postcode={postcode}
                isSelectable={false}
                onDeliveryDateChange={() => {}}
              />
              <p className="mt-2 text-14 leading-[1.2rem] text-grey-5">
                {formatMessage({ id: 'tastics.product.postcode-delivery-calendar.description' })}
              </p>
            </section>
          )}
        </div>
      )}
    </section>
  );
}
