import { RefObject, useEffect, useRef } from 'react';

export default function useOutsideClickRef<T extends Node>(onClick: () => unknown): RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      // FIXME: event.target is throwing TypeScript error when running in Jest. It says that
      // target doesn't exist in MouseEvent
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClick();
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [onClick]);

  return ref;
}
