import { HTMLAttributes, KeyboardEvent, useEffect, useId, useRef, useState } from 'react';
import clsx from 'clsx';
import { MdKeyboardArrowDown } from 'react-icons/md';
import useOutsideClickRef from '~/helpers/hooks/furniturechoice/use-outside-click-ref';

interface DropdownOption {
  label: string | JSX.Element;
  value: string;
  selected?: boolean;
  disabled?: boolean;
}

interface DropdownOptionInternal extends DropdownOption {
  id: string;
}

interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  form?: string;
  name?: string;
  label: string;
  isLabelHidden?: boolean;
  options: DropdownOption[];
  onValueChange?(value: string): void;
}

function buildInternalOption(options: DropdownOption[], idPrefix: string): DropdownOptionInternal[] {
  return options.map((option, index) => ({
    ...option,
    id: `${idPrefix}-${index}`,
  }));
}

export default function Dropdown({
  className,
  form,
  name,
  label,
  isLabelHidden,
  options,
  onValueChange,
  ...props
}: DropdownProps): JSX.Element {
  const optionIdPrefix = useId();
  const [internalOptions, setInternalOptions] = useState<DropdownOptionInternal[]>(
    buildInternalOption(options, optionIdPrefix),
  );
  const [selectedOption, setSelectedOption] = useState<DropdownOptionInternal>(
    internalOptions.find((option) => option.selected) || internalOptions[0],
  );
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>();
  const hostRef = useOutsideClickRef<HTMLDivElement>(() => {
    setFocusedOptionIndex(undefined);
  });

  useEffect(() => {
    const updatedInternalOptions = buildInternalOption(options, optionIdPrefix);
    setInternalOptions(updatedInternalOptions);
    setSelectedOption(updatedInternalOptions.find((option) => option.selected) || updatedInternalOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const comboBoxRef = useRef<HTMLDivElement>(null);
  const labelId = useId();
  const comboBoxId = useId();
  const listBoxId = useId();
  const labelClassName = isLabelHidden ? 'leading-5 visually-hidden' : 'leading-5';
  const ariaActiveDescendant = focusedOptionIndex === undefined ? undefined : internalOptions[focusedOptionIndex].id;

  function expandListBox(): void {
    const focusedIndex = internalOptions.findIndex((option) => option.id === selectedOption.id);

    setFocusedOptionIndex(focusedIndex);
  }

  function selectFocusedOption(): void {
    if (focusedOptionIndex !== undefined) {
      setSelectedOption(internalOptions[focusedOptionIndex]);
      onValueChange?.(internalOptions[focusedOptionIndex].value);
      setFocusedOptionIndex(undefined);
    }
  }

  function handleComboBoxClick(): void {
    if (focusedOptionIndex === undefined) {
      expandListBox();
    } else {
      setFocusedOptionIndex(undefined);
    }
  }

  function handleComboBoxKeyDown(event: KeyboardEvent): void {
    const { key, altKey } = event;

    if (key === 'Enter' || key === ' ') {
      event.preventDefault();

      if (focusedOptionIndex !== undefined) {
        selectFocusedOption();
      } else {
        expandListBox();
      }
    } else if (key === 'ArrowUp') {
      event.preventDefault();

      if (focusedOptionIndex !== undefined) {
        if (altKey) {
          selectFocusedOption();
        } else {
          const maxFocusIndex = 0;
          const testNewFocusIndex = focusedOptionIndex - 1;
          const newFocusIndex = testNewFocusIndex < maxFocusIndex ? maxFocusIndex : testNewFocusIndex;

          setFocusedOptionIndex(newFocusIndex);
        }
      } else {
        expandListBox();
      }
    } else if (key === 'ArrowDown') {
      event.preventDefault();

      if (focusedOptionIndex !== undefined) {
        if (altKey) {
          setFocusedOptionIndex(focusedOptionIndex);
        } else {
          const maxFocusIndex = internalOptions.length - 1;
          const testNewFocusIndex = focusedOptionIndex + 1;
          const newFocusIndex = testNewFocusIndex > maxFocusIndex ? maxFocusIndex : testNewFocusIndex;

          setFocusedOptionIndex(newFocusIndex);
        }
      } else {
        expandListBox();
      }
    } else if (key === 'Home') {
      event.preventDefault();
      setFocusedOptionIndex(0);
    } else if (key === 'End') {
      event.preventDefault();
      setFocusedOptionIndex(internalOptions.length - 1);
    } else if (key === 'Escape') {
      event.preventDefault();
      setFocusedOptionIndex(undefined);
    } else if (key === 'Tab') {
      selectFocusedOption();
    }
  }

  function handleOptionClick(optionIndex: number): void {
    setSelectedOption(internalOptions[optionIndex]);
    onValueChange?.(internalOptions[optionIndex].value);
    setFocusedOptionIndex(undefined);
    comboBoxRef.current?.focus();
  }

  return (
    <div className={clsx('flex flex-col gap-y-4', className)} {...props} ref={hostRef}>
      <label className={labelClassName} id={labelId} data-testid="label">
        {label}
      </label>

      <div className="relative">
        <div
          className="surface-white peer relative flex h-[50px] w-full appearance-none items-center rounded-md border border-solid border-grey-3 pl-[22px] pr-2.5"
          data-testid="combo-box"
          role="combobox"
          id={comboBoxId}
          aria-controls={listBoxId}
          aria-expanded={ariaActiveDescendant !== undefined}
          aria-haspopup="listbox"
          aria-labelledby={labelId}
          aria-activedescendant={ariaActiveDescendant}
          tabIndex={0}
          ref={comboBoxRef}
          onClick={handleComboBoxClick}
          onKeyDown={handleComboBoxKeyDown}
        >
          {selectedOption.label}
          <MdKeyboardArrowDown className="h-6 w-6 fill-grey-5" />
        </div>

        <div
          className={clsx(
            'surface-white absolute inset-x-0 top-full z-300 hidden rounded-b-[2.5px] border-x border-b border-solid border-grey-4',
            'peer-aria-expanded:block',
          )}
          data-testid="list-box"
          role="listbox"
          id={listBoxId}
          aria-labelledby={labelId}
          tabIndex={-1}
        >
          {internalOptions.map((option, index) => (
            /* eslint-disable-next-line
                jsx-a11y/click-events-have-key-events,
                jsx-a11y/interactive-supports-focus
            */
            <div
              className={clsx('px-[22px] py-3 lg:text-18', 'hover:surface-grey-2', {
                'focused-option surface-grey-2': focusedOptionIndex === index,
              })}
              data-testid={`option-${index}`}
              role="option"
              id={option.id}
              key={option.value}
              aria-selected={selectedOption.id === option.id}
              onClick={() => handleOptionClick(index)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>

      <input data-testid="input" type="hidden" value={selectedOption.value} form={form} name={name} />
    </div>
  );
}
