import { HTMLAttributes } from 'react';
import clsx from 'clsx';

export default function Spinner({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div
      className={clsx(
        'h-8 w-8 animate-spin rounded-full border-4 border-solid border-salmon-1 border-b-transparent lg:h-12 lg:w-12',
        className,
      )}
      {...props}
    />
  );
}
