import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { FNCBundle, FNCVariant } from 'shared/types/product/Product';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';
import BundleSavings from './bundle-savings';

interface PricingProps {
  variant: FNCVariant;
  bundles: FNCBundle[];
}

export default function Pricing({ variant, bundles }: PricingProps): JSX.Element {
  const { locale } = useParams();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="mt-5">
      <p
        className={clsx('text-26 font-semibold', {
          'text-red-2': variant.discountedPrice || variant.attributes?.['descriptors_is-clearance'],
        })}
        data-testid="main-price"
      >
        {CurrencyHelpers.formatForCurrency(variant.discountedPrice || variant.price || 0, locale)}
      </p>

      {variant.discountedPrice && (
        <p className="mt-1 line-through" data-testid="compare-at-price">
          {formatMessage({ id: 'tastics.product.content-manager.was' })}{' '}
          {CurrencyHelpers.formatForCurrency(variant.price || 0, locale)}
        </p>
      )}

      {!variant.discountedPrice && bundles.length > 0 && <BundleSavings variant={variant} bundles={bundles} />}
    </div>
  );
}
