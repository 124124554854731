import { FNCProductOption, FNCVariant } from 'shared/types/product/Product';
import { getProductTypeAttributeValue } from 'shared/utils/get-product-type-attribute-value';

export type VariantOption = {
  label: string;
  type: string;
  values: VariantOptionValue[];
};

export type VariantOptionValue = {
  value: string;
  variant: FNCVariant | null;
  to: string;
  isActive: boolean;
};

export default function buildVariantOptions(variants: FNCVariant[], options: FNCProductOption[]): VariantOption[] {
  const optionsConfig: Record<string, string> = { options_size: 'dropdown', options_colour: 'swatch' };
  const variantOptions = options.map((option) => {
    const otherOptions = options.filter((item) => item.name !== option.name);

    return {
      label: `${option.label} options:`,
      type: optionsConfig[option.name] || 'dropdown',
      values: option.values.map((value) => {
        const variant = variants.find((variant) => {
          const isAllOtherOptionsSelected = otherOptions.every(
            (otherOption) =>
              getProductTypeAttributeValue(variant.attributes?.[otherOption.name]) === otherOption.selected,
          );

          return getProductTypeAttributeValue(variant.attributes?.[option.name]) === value && isAllOtherOptionsSelected;
        });

        if (variant) {
          return {
            value,
            variant,
            to: `${variant.key}_${variant.sku}`.toLowerCase(),
            isActive: options.every(
              (option) => getProductTypeAttributeValue(variant.attributes?.[option.name]) === option.selected,
            ),
          };
        }

        return {
          value,
          variant: null,
          to: '',
          isActive: false,
        };
      }),
    };
  });

  return variantOptions.filter((variantOption) => {
    const values = variantOption.values.filter(({ variant }) => variant !== null);

    return values.length > 0;
  });
}
