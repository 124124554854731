'use client';

import { RefObject, useLayoutEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { SwiperContainer, register } from 'swiper/element/bundle';
import { SwiperProps } from 'swiper/react';

interface UseSwiperData {
  ref: RefObject<SwiperContainer>;
  props: SwiperProps;
}

export default function useSwiper(data?: UseSwiperData): { isInitialized: boolean } {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useLayoutEffect(() => {
    register();

    if (data && data.ref.current) {
      Object.assign(data.ref.current, data.props);

      data.ref.current.initialize();
    }

    setIsInitialized(true);
  }, [data]);

  return { isInitialized };
}
