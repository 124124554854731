import { ShippingMethod } from 'shared/types/cart';
import DXCalendar from './dx-calendar';
import LocalCalendar from './local-calendar';

interface CalendarManagerProps {
  shippingMethod: ShippingMethod;
  defaultValue?: string;
  postcode?: string;
  isSelectable?: boolean;
  onDeliveryDateChange(date: Date | null): void;
}

export default function CalendarManager({
  shippingMethod,
  defaultValue,
  postcode,
  isSelectable = true,
  onDeliveryDateChange,
}: CalendarManagerProps): JSX.Element {
  const { custom } = shippingMethod;
  const deliveryCalendar = custom?.deliveryCalendar;

  return (
    <>
      {deliveryCalendar === 'dx-F3700011' ? (
        <DXCalendar
          accountNumber="F3700011"
          defaultValue={defaultValue}
          postcode={postcode}
          isSelectable={isSelectable}
          onDeliveryDateChange={onDeliveryDateChange}
        />
      ) : deliveryCalendar === 'dx-F5754700' ? (
        <DXCalendar
          accountNumber="F5754700"
          defaultValue={defaultValue}
          postcode={postcode}
          isSelectable={isSelectable}
          onDeliveryDateChange={onDeliveryDateChange}
        />
      ) : (
        <LocalCalendar
          shippingMethodId={shippingMethod.shippingMethodId}
          defaultValue={defaultValue}
          isSelectable={isSelectable}
          onDeliveryDateChange={onDeliveryDateChange}
        />
      )}
    </>
  );
}
