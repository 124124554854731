import { HTMLAttributes } from 'react';
import FNCButton from '../fnc/fnc-button';

interface ProgressBarProps extends HTMLAttributes<HTMLDivElement> {
  total: number;
  current: number;
  hasMore: boolean;
  buttonText: string;
  onClick: () => void;
}

export default function ProgressBar({ total, current, hasMore, buttonText, onClick, children }: ProgressBarProps) {
  let barwidth = (current / (total || 1)) * 100;
  if (barwidth > 100) barwidth = 100;
  if (total <= 0 || current <= 0) return <></>;

  return (
    <div className="mx-auto my-8 flex max-w-xs flex-col">
      <span className="text-center text-16 font-light leading-5">{children}</span>
      <div className="mx-3 mt-[10px] h-[5px] rounded-md bg-grey-2">
        <div className="h-[5px] rounded-md bg-salmon-1" style={{ width: barwidth.toString() + '%' }}></div>
      </div>
      {hasMore && (
        <FNCButton onClick={onClick} className="surface-salmon-1 mt-8 w-full rounded-md p-4">
          {buttonText}
        </FNCButton>
      )}
    </div>
  );
}
