import { useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { FNCVariant, Product } from 'shared/types/product/Product';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import SideModal from '~/components/furniturechoice/side-modal';
import { useCart } from '~/frontastic/hooks';
import { useFormat } from '~/helpers/hooks/useFormat';
import SwatchSelection from './swatch-selection';

interface OrderSwatchesSectionProps {
  swatches: Product[];
  selectedVariant: FNCVariant;
}

export default function OrderSwatchesSection({ swatches, selectedVariant }: OrderSwatchesSectionProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [swatchSkusInCart, setSwatchSkusInCart] = useState<string[]>([]); // State to hold swatch skus in cart
  const cart = useCart();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const handleOrderSwatchesClick = () => {
    if (cart.data?.lineItems) {
      const skus = cart.data.lineItems.map((item) => item.variant.sku);
      setSwatchSkusInCart(skus);
    }
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <FNCButton
        className="surface-white mt-4 h-[50px] w-full border border-grey-3 px-4"
        onClick={handleOrderSwatchesClick}
      >
        <p className="flex items-center leading-4">
          <span className="text-14 text-grey-5">
            {formatMessage({ id: 'tastics.product.content-manager.order_free_swatches' })}
          </span>

          <MdChevronRight className="text-16 text-grey-4" />
        </p>

        <p className="text-12 font-light leading-4 text-grey-4">
          {formatMessage({ id: 'tastics.product.content-manager.free_first_class_delivery' })}
        </p>
      </FNCButton>

      <SideModal
        title={formatMessage({ id: 'tastics.product.content-manager.select_your_free_swatch' })}
        isOpen={isModalOpen}
        onModalClose={handleModalClose}
      >
        <>
          <p className="text-grey-5">
            {formatMessage({ id: 'tastics.product.content-manager.you_can_select_up_to_5_maximum_free_swatch' })}
          </p>

          <SwatchSelection
            swatches={swatches}
            selectedVariant={selectedVariant}
            onClose={handleModalClose}
            swatchSkusInCart={swatchSkusInCart}
          />
        </>
      </SideModal>
    </div>
  );
}
