import { FNCBundle, FNCVariant } from 'shared/types/product/Product';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';

interface BundleSavingsProps {
  variant: FNCVariant;
  bundles: FNCBundle[];
}

export default function BundleSavings({ variant, bundles }: BundleSavingsProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const originalPrice = bundles.reduce((prev, current) => {
    const priceBasis =
      current.product.variants[0].discountedPrice?.centAmount || current.product.variants[0].price?.centAmount || 0;

    return prev + priceBasis * current.quantity;
  }, 0);
  const savedPrice = originalPrice - (variant.price?.centAmount || 0);
  const originalPriceMoney = CurrencyHelpers.formatForCurrency({ ...variant.price, centAmount: originalPrice });
  const savedPriceMoney = CurrencyHelpers.formatForCurrency({ ...variant.price, centAmount: savedPrice });

  return (
    <p className="mt-4 text-14 leading-4.5" data-testid="bundle-savings">
      {formatMessage({
        id: 'tastics.product.content-manager.bundle_savings',
        values: { savedPrice: savedPriceMoney, originalPrice: originalPriceMoney },
      })}
    </p>
  );
}
