import { useState, HTMLAttributes, useRef, MutableRefObject } from 'react';
import ProgressBar from '~/components/furniturechoice/progress-bar';
import { useFormat } from '~/helpers/hooks/useFormat';
import FetchReviews from './FetchReviews';

interface ReviewManagerProps extends HTMLAttributes<HTMLDivElement> {
  targetId: string;
  totalReviews: number;
  page: MutableRefObject<number>;
  rows: number;
}

export default function ReviewManager({ targetId, totalReviews, page, rows, ...props }: ReviewManagerProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const [currentPage, setCurrentPage] = useState(page.current);
  const pages = [];
  const currentCount = useRef<number[]>([]);
  const [loadedReviewCount, setLoadedReviewCount] = useState(0);

  const callback = (index: number, count: number) => {
    currentCount.current[index] = count;
    let temp = 0;
    for (const i in currentCount.current) {
      temp += currentCount.current[i];
    }
    setLoadedReviewCount(temp);
  };

  for (let i = 0; i <= currentPage; i++) {
    pages.push(<FetchReviews callback={callback} key={i} targetId={targetId} page={i} rows={rows} />);
  }

  return (
    <div {...props}>
      <div>{pages}</div>
      <ProgressBar
        total={totalReviews}
        current={loadedReviewCount}
        hasMore={loadedReviewCount < totalReviews}
        buttonText={formatMessage({
          id: 'tastics.review.load_more',
        })}
        onClick={() => {
          page.current = currentPage + 1;
          setCurrentPage(currentPage + 1);
          console.log(page.current);
        }}
      >
        {formatMessage({
          id: 'tastics.review.showing_count',
          values: { currentCount: loadedReviewCount, totalCount: totalReviews },
        })}
      </ProgressBar>
    </div>
  );
}
