import { FormEvent, useState, useContext } from 'react';
import { FNCProduct } from 'shared/types/product/Product';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import submitReview from './submitReview';
import WriteReviewForm from './WriteReviewForm';
import WriteReviewSent from './WriteReviewSent';

export interface ReturnResult {
  ok: boolean;
  error: string;
}

export default function WriteReviewManager({ product }: { product: FNCProduct }) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const [reviewSent, setReviewSent] = useState(false);

  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    showProgressIndicator();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);

    const result = await submitReview(payload);

    hideProgressIndicator();

    if (!result || result.isError == true) {
      // todo: real alert
      if (result) {
        alert(result.error);
      } else {
        // todo: real alert
        alert(formatMessage({ id: 'general.error_message.unknown_error' }));
      }
    } else {
      if (result.data.ok) {
        setReviewSent(true);
      } else {
        // todo: real alert
        alert(result.data.error);
      }
    }
  }

  return (
    <div className="">
      <h2 className="font-sans text-20 font-bold text-grey-6">
        {formatMessage({ id: 'tastics.review.write_a_review' })}
      </h2>
      {reviewSent ? <WriteReviewSent /> : <WriteReviewForm product={product} onSubmit={handleSubmit} />}
    </div>
  );
}
