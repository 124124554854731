import { forwardRef, ForwardedRef } from 'react';
import clsx from 'clsx';
import { MdKeyboardArrowDown } from 'react-icons/md';
import DynamicTag from '~/components/furniturechoice/dynamic-tag';

interface HeadingProps {
  headingId: string;
  headingTag: string;
  headingText: string;
  panelId: string;
  isExpanded: boolean;
  onClick(): void;
  headingElem?: JSX.Element;
  navigationId?: string;
}

export function Heading(
  { headingId, headingTag, headingText, headingElem, panelId, isExpanded, onClick, navigationId }: HeadingProps,
  ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element {
  return (
    <DynamicTag as={headingTag} data-testid="heading">
      <button
        className="flex w-full items-center justify-between overflow-hidden py-10"
        data-testid="button"
        type="button"
        id={headingId}
        aria-expanded={isExpanded}
        aria-controls={panelId}
        onClick={onClick}
        ref={ref}
      >
        <div id={navigationId} className="flex gap-8 text-left">
          <span className="text-28 font-medium leading-9">{headingText}</span>
          {headingElem}
        </div>
        <MdKeyboardArrowDown
          className={clsx('fill-grey-5 text-24 transition-transform duration-standard ease-standard', {
            'rotate-180': isExpanded,
          })}
        />
      </button>
    </DynamicTag>
  );
}

export default forwardRef<HTMLButtonElement, HeadingProps>(Heading);
