import { useContext } from 'react';
import { FNCShopTheCollection } from 'shared/types/product/Product';
import useSWR, { SWRResponse } from 'swr';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';

export default function useShopTheCollection(
  baseIdCollection: string | null,
  baseIdShopTheCollection: string | null,
  bomSkus: string[] | null,
): SWRResponse<FNCShopTheCollection> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const key = `${baseIdShopTheCollection}|${baseIdCollection}|${bomSkus?.join(',')}`;

  return useSWR<FNCShopTheCollection>(`/furniturechoice-product/getShopTheCollection/${key}`, async () => {
    const response = await executeWithProgress(() =>
      sdk.callAction<FNCShopTheCollection>({
        actionName: 'furniturechoice-product/getShopTheCollection',
        payload: {
          baseIdCollection,
          baseIdShopTheCollection,
          bomSkus,
        },
      }),
    );

    if (response.isError) {
      throw response.error;
    }

    return response.data;
  });
}
