import { useContext } from 'react';
import { VimeoVideo } from 'shared/types/furniturechoice/vimeo-video';
import useSWR, { SWRResponse } from 'swr';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

export default function useVimeo(id?: string): SWRResponse<VimeoVideo> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  return useSWR<VimeoVideo>(id ? `/api/vimeo/${id}` : null, async () => {
    const url = `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${id}&responsive=true`;
    const response = await executeWithProgress(() =>
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error fetching Vimeo data: ${response.status} ${response.statusText} - ${errorText}`);
    }

    return response.json();
  });
}
