import { HTMLAttributes } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import FNCStarRating from '~/components/furniturechoice/fnc/fnc-star-rating';
import FNCTextarea from '~/components/furniturechoice/fnc/fnc-textarea';
import Link from '~/components/furniturechoice/link';
import { useAccount } from '~/frontastic/hooks';
import { useFormat } from '~/helpers/hooks/useFormat';

interface WriteReviewFormProps extends HTMLAttributes<HTMLFormElement> {
  product: FNCProduct;
}

export default function WriteReviewForm({ product, onSubmit }: WriteReviewFormProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const accountManager = useAccount();

  const customerName = accountManager.account
    ? (accountManager.account.firstName + ' ' + accountManager.account.lastName).trim()
    : '';
  const customerEmail = accountManager.account ? accountManager.account.email : '';

  return (
    <form method="post" onSubmit={onSubmit}>
      <div className="mt-4 grid gap-4 font-normal text-grey-5">
        <p>{formatMessage({ id: 'tastics.review.write_review_text' })}</p>
        <p>
          <Link
            className="text-salmon-1 underline"
            /*target="_blank"*/
            href="https://help.furniturechoice.co.uk/hc/en-gb/articles/5147733384593-Product-review-guidelines"
          >
            {formatMessage({ id: 'tastics.review.review_guidelines' })} <MdOpenInNew className="inline" />
          </Link>
        </p>
        <div className="grid gap-4">
          <input type="hidden" name="product" value={product.productId} />
          <input type="hidden" name="range" value={product.selectedVariant.attributes?.baseid_review} />
          <input type="hidden" name="customer" value={accountManager.account?.accountId} />
          <FNCInput
            type="text"
            name="name"
            label={formatMessage({ id: 'tastics.review.name' })}
            defaultValue={customerName}
            maxLength={255}
            required
          />
          <FNCInput
            type="text"
            name="order_number"
            label={formatMessage({ id: 'tastics.review.order_number' })}
            defaultValue=""
            maxLength={100}
            required
          />
          <FNCInput
            type="email"
            name="email"
            label={formatMessage({ id: 'tastics.review.email' })}
            maxLength={255}
            defaultValue={customerEmail}
          />
          <FNCTextarea
            role="input"
            name="comments"
            label={formatMessage({ id: 'tastics.review.comments' })}
            defaultValue=""
            required
          />
          <div className="grid grid-cols-2 gap-4">
            <FNCStarRating
              label={formatMessage({ id: 'tastics.review.overall' })}
              name="rating_overall"
              feedback={formatMessage({ id: 'tastics.review.rating_is_required' })}
              required
            />
            <FNCStarRating
              label={formatMessage({ id: 'tastics.review.price' })}
              name="rating_price"
              feedback={formatMessage({ id: 'tastics.review.price_is_required' })}
              required
            />
            <FNCStarRating
              label={formatMessage({ id: 'tastics.review.quality' })}
              name="rating_quality"
              feedback={formatMessage({ id: 'tastics.review.quality_is_required' })}
              required
            />
          </div>
          <FNCButton type="submit" className="surface-green-2 mt-4">
            {formatMessage({ id: 'tastics.review.submit' })}
          </FNCButton>
        </div>
      </div>
    </form>
  );
}
