import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { MdCheck } from 'react-icons/md';
import { FNCVariant } from 'shared/types/product/Product';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import Link from '~/components/furniturechoice/link';
import { VariantOption, VariantOptionValue } from '../build-variant-options';

interface SwatchSelectorProps {
  option: VariantOption;
}

interface NoNullVariantOptionValue extends VariantOptionValue {
  variant: FNCVariant;
}

export default function SwatchSelector({ option }: SwatchSelectorProps): JSX.Element {
  const pathname = usePathname();
  const pathPrefix = pathname.split('/').slice(0, -2).join('/');
  const validOptionValues = option.values.filter(({ variant }) => variant !== null) as NoNullVariantOptionValue[];
  const activeOption = option.values.find(({ isActive }) => isActive) as VariantOptionValue;

  return (
    <div className="flex flex-col gap-y-4">
      <label className="leading-5">
        <span>{option.label} </span>
        <span className="font-semibold">{activeOption?.value}</span>
      </label>

      <div className="flex flex-wrap gap-x-2 gap-y-3">
        {validOptionValues.map(({ value, to, variant, isActive }) => (
          <Link
            key={value}
            className={clsx('relative aspect-square h-10 w-10 rounded-full border-2 p-0.5', {
              'border-transparent': !isActive,
              'border-2 border-solid border-green-1': isActive,
            })}
            href={`${pathPrefix}/${to}`}
          >
            <ContentfulImage
              className="h-full w-full rounded-full"
              data-testid="swatch-image"
              src={variant.metafields.swatch?.uri}
              sizes="40px"
            />
            {isActive && (
              <div className="absolute -left-1.5 -top-1.5 flex h-5 w-5 items-center justify-center rounded-full border-2 border-solid border-white bg-green-1 p-0.5">
                <MdCheck className="fill-white" />
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
}
