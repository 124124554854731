import { RefObject, useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { VimeoVideo } from 'shared/types/furniturechoice/vimeo-video';
import { SwiperContainer } from 'swiper/element';
import 'photoswipe/style.css';
import 'styles/components/photoswipe.css';

export default function useSwiperPhotoswipe(
  ref: RefObject<SwiperContainer>,
  id: string,
  vimeoVideo?: VimeoVideo,
): void {
  useEffect(() => {
    let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
      gallery: `#${CSS.escape(id)}`,
      children: 'a',
      spacing: 0,
      zoom: false,
      counter: true,
      allowPanToNext: false,
      arrowNextSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" style="fill:rgba(255, 255, 255, 0.7)"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>',
      arrowPrevSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" style="fill:rgba(255, 255, 255, 0.7)"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/></svg>',
      closeSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" style="fill:rgba(255, 255, 255, 0.7)"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>',
      pswpModule: () => import('photoswipe'),
    });

    lightbox.init();

    lightbox.on('contentActivate', ({ content }) => {
      if (ref?.current) {
        ref.current.swiper.slideTo(content.index);
      }
    });

    lightbox.addFilter('itemData', (itemData, index) => {
      if (index === 1 && vimeoVideo) {
        return {
          html: `<div class="pswp__fnc-video">${vimeoVideo.html}</div>`,
        };
      }
      return itemData;
    });

    const animatedGoTo = (index: number, animate = false) => {
      const ctx = (lightbox as PhotoSwipeLightbox).pswp;

      if (ctx) {
        ctx.mainScroll.moveIndexBy(ctx.getLoopedIndex(index) - ctx.potentialIndex, animate);
      }
    };

    lightbox.on('uiRegister', () => {
      if (lightbox?.pswp) {
        lightbox.pswp.next = () => {
          if (lightbox?.pswp) animatedGoTo(lightbox.pswp.potentialIndex + 1, true);
        };

        lightbox.pswp.prev = () => {
          if (lightbox?.pswp) animatedGoTo(lightbox.pswp.potentialIndex - 1, true);
        };
      }
    });

    return () => {
      if (lightbox) {
        lightbox.destroy();
        lightbox = null;
      }
    };
  }, [id, ref, vimeoVideo]);
}
