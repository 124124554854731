import { useContext } from 'react';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import { LineItem } from 'shared/types/wishlist';
import { useWishlist } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

interface WishlistButtonProps {
  product: FNCProduct;
}

export default function WishlistButton({ product }: WishlistButtonProps): JSX.Element {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const wishlistManager = useWishlist();

  const lineItem = wishlistManager.data?.lineItems?.find((lineItem) => lineItem.productId === product.productId);

  function handleClick() {
    executeWithProgress(async () => {
      if (wishlistManager.data) {
        if (lineItem) {
          await wishlistManager.removeLineItem(wishlistManager.data, lineItem);
        } else {
          const optimisticLineItem: LineItem = {
            lineItemId: product.productId || '',
            productId: product.productId,
            variant: product.selectedVariant,
          };

          await wishlistManager.addToWishlist(wishlistManager.data, optimisticLineItem);
        }
      }
    });
  }

  return (
    <button
      className="surface-white flex min-h-[48px] min-w-[48px] items-center justify-center rounded-md border-[1px] border-grey-3"
      onClick={handleClick}
      data-testid="wishlist-button"
    >
      {lineItem ? (
        <MdFavorite className="fill-red-3 text-24" data-testid="favorite-filled" />
      ) : (
        <MdFavoriteBorder className="text-24 text-grey-4" data-testid="favorite-empty" />
      )}
    </button>
  );
}
