import { FNCProduct, Product } from 'shared/types/product/Product';
import Spinner from '~/components/furniturechoice/spinner';
import useProductSpecs from './use-product-specs';

interface TableItemProps {
  product: FNCProduct | Product;
}

export default function TableItem({ product }: TableItemProps): JSX.Element {
  const { data, isLoading } = useProductSpecs(product.productId as string);

  if (isLoading) return <Spinner className="mx-auto" />;

  return (
    <div className="flex flex-col gap-y-6">
      <h3 className="font-sans text-18 font-semibold leading-5 text-grey-5">{product.name}</h3>

      <ul>
        {data?.map((spec) => (
          <li key={spec.label} className="odd:surface-grey-1 grid grid-cols-[25%_1fr] gap-x-3 p-4 odd:rounded-md">
            <div className="text-13 font-semibold leading-4" data-testid="label">
              {spec.label}
            </div>

            <div className="text-13 leading-4" data-testid="value">
              {spec.value}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
