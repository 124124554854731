import Image, { ImageProps } from 'next/image';
import clsx from 'clsx';

export default function GenericImage({ ...props }: ImageProps): JSX.Element {
  if (props.src) {
    // @ts-ignore: Property "key" seems to have a mismatch :/
    return <Image {...props} />;
  }

  return <div className={clsx('aspect-square w-full bg-grey-1', props.className)} />;
}
