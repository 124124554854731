import { FNCProductDimension, Product } from 'shared/types/product/Product';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import Spinner from '~/components/furniturechoice/spinner';
import useProductDimensions from './use-product-dimensions';

interface DimensionTableProps {
  product: Product;
}

function parseDimensionLabel(dimension: FNCProductDimension): string {
  if (typeof dimension.value === 'boolean') {
    return dimension.label;
  }

  return `${dimension.label}:`;
}

function parseDimensionValue(value: unknown): string {
  switch (typeof value) {
    case 'number':
      return `${value.toFixed(1)} cm`;
    case 'boolean':
      return '';
    default:
      return value as string;
  }
}

export default function DimensionTable({ product }: DimensionTableProps): JSX.Element {
  const { data, isLoading } = useProductDimensions(product.productId as string);

  if (isLoading) return <Spinner className="mx-auto" />;

  return (
    <div className="flex flex-col gap-y-5">
      <h3 className="font-sans text-18 font-semibold">{product.name}</h3>

      <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
        {data?.map((dimension) => (
          <div key={dimension.label} className="flex flex-col items-center gap-y-4 text-center">
            <ContentfulImage
              className="aspect-[317/173] w-full p-3"
              colSpanPercent={0.5}
              colSpanPercentLg={0.25}
              aspectRatio={375 / 173}
              src={dimension.image?.fields.file.url}
            />

            <div className="flex flex-col gap-y-2">
              <h4 className="font-sans text-13 font-bold text-grey-4" data-testid="label">
                {parseDimensionLabel(dimension)}
              </h4>

              <p className="text-13 font-bold" data-testid="value">
                {parseDimensionValue(dimension.value)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
