import Review from 'shared/types/review/Review';
import useSWR from 'swr';
import { sdk } from '~/sdk';

export function useReview(key: string, page: number, rows: number) {
  return useSWR(`getReviews/${key}/${page}/${rows}`, () =>
    sdk
      .callAction<Review[]>({
        actionName: 'furniturechoice-review/getReviews',
        query: { key: key, page: page, rows: rows },
      })
      .then((res) => res),
  );
}

export default useReview;
