import { FNCProductBomInfo } from 'shared/types/furniturechoice/product';
import { FNCProduct } from 'shared/types/product/Product';
import SectionHeader from '~/components/furniturechoice/section-header';
import { useFormat } from '~/helpers/hooks/useFormat';
import ProductCardGrid from './product-card-grid';
import ProductCardSlider from './product-card-slider';
import useShopTheCollection from './useShopTheCollection';

interface ShopTheCollectionProp {
  product: FNCProduct;
}

export default function ShopTheCollection({ product }: ShopTheCollectionProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const isBomProduct = !!product.selectedVariant.attributes?.['descriptors_bom-info'];
  const bomProduct: FNCProductBomInfo = isBomProduct
    ? JSON.parse(product.selectedVariant.attributes?.['descriptors_bom-info'])
    : undefined;
  const bomProductSkus = bomProduct && bomProduct.bomProducts.map((i) => i.sku);

  const attributes = product.selectedVariant.attributes;
  const shouldFetchCollection =
    attributes?.['baseid_collections']?.length > 0 && !!attributes?.['baseid_shop-the-collection'];

  const { data, isLoading } = useShopTheCollection(
    shouldFetchCollection ? attributes?.['baseid_collections'][0] : null,
    shouldFetchCollection ? attributes?.['baseid_shop-the-collection'] : null,
    bomProductSkus ? bomProductSkus : null,
  );

  if (isLoading) return <div className="standard-section-padding" />;

  if (!data) return <></>;

  return (
    <section className="standard-section-padding">
      <div className="container">
        <SectionHeader as="h2">
          {formatMessage({ id: 'tastics.product.content-manager.shop-the-collection.header' })}
        </SectionHeader>

        {data && data.products.length > 0 ? (
          <>
            <ProductCardGrid products={data.products} collectionUrl={data.url} />
            <ProductCardSlider products={data.products} collectionUrl={data.url} />
          </>
        ) : (
          <div className="h-[200px]">
            {/* Placeholder height to maintain layout */}
            {/* TODO: Create a product cart skeleton for loading state to maintain size. */}
          </div>
        )}
      </div>
    </section>
  );
}
