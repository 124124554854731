import { MdChevronRight } from 'react-icons/md';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import { Money } from 'shared/types/product';
import { calculate, calculateDeposit } from '~/components/furniturechoice/finance-calculator/util/calculate';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';

interface ZeroFinanceProp {
  financeableAmount?: Money;
  financeProducts?: FinanceProduct[];
  onClick?: () => void;
}

export default function ZeroFinanceLabel({
  financeableAmount,
  financeProducts,
  onClick,
}: ZeroFinanceProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const FINANCE_MIN_AMT = parseInt(process.env.NEXT_PUBLIC_MIN_FINANCE_AMOUNT ?? '0');
  const defaultDepositOptions = [50, 40, 30, 20, 10];

  if (!financeableAmount || !financeProducts) {
    return <></>;
  }

  if (!financeableAmount.centAmount || financeableAmount.centAmount < FINANCE_MIN_AMT * 100) {
    return <></>;
  }

  const monthlyAmount = (() => {
    if (financeableAmount.centAmount) {
      const zeroInterestTerm = financeProducts.find((f) => f.apr === 0) ?? financeProducts[0];
      const amountInCurrency = CurrencyHelpers.formatForCurrency(financeableAmount);
      const amountToString = amountInCurrency.slice(1).replace(',', '');
      const amountInNumber = parseFloat(amountToString);
      const depositPercent = defaultDepositOptions.find((value) => {
        const tempDeposit = calculateDeposit(amountInNumber, value);
        return amountInNumber - tempDeposit >= zeroInterestTerm.minLoan;
      });
      const depositAmount = calculateDeposit(amountInNumber, depositPercent ?? 0);
      const x = calculate(zeroInterestTerm, amountInNumber, depositAmount);

      return x.initialPayments;
    }

    return '0';
  })();

  return (
    <div className="mt-2 lg:mt-3">
      <span
        data-testid={monthlyAmount}
        className="flex cursor-pointer text-14 font-medium leading-3 text-salmon-4 underline"
        onClick={onClick}
      >
        {formatMessage({
          id: 'tastics.product.content-manager.zero_finance',
          defaultMessage: '0% APR finance from £{amount}/mo',
          values: { amount: monthlyAmount },
        })}
        <MdChevronRight />
      </span>
    </div>
  );
}
