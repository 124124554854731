import { FNCProduct } from 'shared/types/product/Product';
import ReviewStars from '~/components/furniturechoice/review/ReviewStars';
import { ReviewSummaryHelper } from '~/helpers/furniturechoice/review/review-summary';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function ReviewWidget({ product }: { product: FNCProduct }) {
  const stats = product.reviewRatingStatistics;
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  if (!stats || !stats.count) return <></>;

  return (
    <div className="grid gap-1 text-left font-sans">
      <div className="flex gap-2 align-middle">
        <span className="inline-block text-16 font-semibold leading-6">
          {ReviewSummaryHelper.getAverageRating(stats)}
        </span>
        <ReviewStars stars={stats.averageRating} className="fill-grey-5 text-18" />
      </div>
      <div className="text-14 font-normal text-grey-4">{formatMessage(ReviewSummaryHelper.getReviewCount(stats))}</div>
    </div>
  );
}
