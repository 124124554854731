import { useId, InputHTMLAttributes, HTMLAttributes, useState, ChangeEvent } from 'react';
import clsx from 'clsx';
import { MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';

interface FNCStarRatingProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  feedback?: string;
}

export default function FNCStarRating({ className, label, name, feedback, ...props }: FNCStarRatingProps): JSX.Element {
  const id = useId();
  const [valid, setValid] = useState(true);
  const [ratingChecked, setRatingChecked] = useState(0);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRatingChecked(parseInt(e.target.value));
    setValid(true);
  };

  const handleInvalid = () => {
    setTimeout(() => {
      setValid(false);
    }, 500);
  };

  interface StarProps extends HTMLAttributes<HTMLLabelElement> {
    id: string;
  }
  interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
    pair: string;
  }

  const Star = ({ id, title }: StarProps) => (
    <label htmlFor={id} className="relative inline-block h-5 w-5" title={title}>
      <MdOutlineStar className="fade-in-initial absolute h-5 w-5 fill-salmon-1 text-18" />
      <MdOutlineStarBorder className="absolute z-10 h-5 w-5 fill-salmon-1 text-18" />
    </label>
  );

  const Radio = ({ id, pair, value, checked }: RadioProps) => (
    <input
      className={clsx(pair, 'relative h-0 w-0 appearance-none')}
      id={id}
      type="radio"
      name={name}
      value={value}
      onChange={changeHandler}
      onInvalid={handleInvalid}
      {...props}
      checked={checked}
    />
  );

  return (
    <fieldset className={className} name={name}>
      <legend className="text-16 text-grey-5">{label}</legend>
      <div className={clsx('flex gap-x-2')}>
        <div role="radiogroup" className="relative flex" aria-labelledby={id + '_label'}>
          <Radio id={id + 1} pair="peer/one" value="1" checked={ratingChecked == 1} />
          <Radio id={id + 2} pair="peer/two" value="2" checked={ratingChecked == 2} />
          <Radio id={id + 3} pair="peer/three" value="3" checked={ratingChecked == 3} />
          <Radio id={id + 4} pair="peer/four" value="4" checked={ratingChecked == 4} />
          <Radio id={id + 5} pair="peer/five" value="5" checked={ratingChecked == 5} />
          <div
            id={id + '_label'}
            className={clsx(
              'mt-1 flex gap-1',
              'peer-checked/one:[&>*:nth-child(1)]:[&>*:nth-child(1)]:fade-in-enter',
              'peer-checked/two:[&>*:nth-child(1)]:[&>*:nth-child(-n+2)]:fade-in-enter',
              'peer-checked/three:[&>*:nth-child(1)]:[&>*:nth-child(-n+3)]:fade-in-enter',
              'peer-checked/four:[&>*:nth-child(1)]:[&>*:nth-child(-n+4)]:fade-in-enter',
              'peer-checked/five:[&>*:nth-child(1)]:[&>*:nth-child(-n+5)]:fade-in-enter',
            )}
          >
            <Star id={id + 1} title="1" />
            <Star id={id + 2} title="2" />
            <Star id={id + 3} title="3" />
            <Star id={id + 4} title="4" />
            <Star id={id + 5} title="5" />
          </div>
        </div>
      </div>
      {feedback && !valid && (
        <div className="text-14 leading-[18px] text-red-1" role="alert" data-testid="feedback">
          {feedback}
        </div>
      )}
    </fieldset>
  );
}
