import { MdArrowForwardIos } from 'react-icons/md';
import Link from '~/components/furniturechoice/link';

interface ViewCollectionCardProps {
  collectionUrl: string;
}

export default function ViewCollectionCard({ collectionUrl }: ViewCollectionCardProps): JSX.Element {
  return (
    <Link
      data-testid="view-collection-link"
      className="surface-grey-5 flex items-center justify-center rounded-md lg:h-full"
      href={collectionUrl}
    >
      <span className="text-13 font-semibold leading-3.5 lg:text-18 lg:leading-5">
        View Collection <MdArrowForwardIos className="inline" />
      </span>
    </Link>
  );
}
