import { FNCProductOption, FNCVariant } from 'shared/types/product/Product';
import buildVariantOptions from './build-variant-options';
import DropdownSelector from './dropdown-selector';
import SwatchSelector from './swatch-selector';

interface VariantSelectorProps {
  variants: FNCVariant[];
  options: FNCProductOption[];
}

export default function VariantSelector({ variants, options }: VariantSelectorProps): JSX.Element {
  const variantOptions = buildVariantOptions(variants, options);

  return (
    <div className="mt-6 flex flex-col gap-y-5">
      {variantOptions.map((variantOption) => {
        switch (variantOption.type) {
          case 'dropdown':
            return <DropdownSelector key={variantOption.label} option={variantOption} />;
          case 'swatch':
            return <SwatchSelector key={variantOption.label} option={variantOption} />;
          default:
            return <DropdownSelector key={variantOption.label} option={variantOption} />;
        }
      })}
    </div>
  );
}
