import { Fragment } from 'react';
import Review from 'shared/types/review/Review';
import ReviewItem from './ReviewItem';

export default function ReviewList({ reviews }: { reviews: Review[] }) {
  return (
    <>
      {reviews && reviews.length ? (
        reviews.map((review) => (
          <Fragment key={review.id}>
            <ReviewItem review={review} />
          </Fragment>
        ))
      ) : (
        <></>
      )}
    </>
  );
}
