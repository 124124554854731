'use client';

import clsx from 'clsx';
import { GoTools } from 'react-icons/go';
import { FNCProduct } from 'shared/types/product/Product';
import { useFormat } from '~/helpers/hooks/useFormat';

interface PageDescriptionProps {
  product: FNCProduct;
}

export default function PageDescription({ product }: PageDescriptionProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <section>
      {product.description && (
        <div
          className={clsx(
            'flex flex-col gap-4 text-grey-5 lg:gap-6',
            // <strong> / <b>
            '[&_b]:font-medium [&_strong]:font-medium',

            // <a>
            '[&_a]:text-salmon-4 [&_a]:hover:underline',

            // <p>
            '[&_p]:leading-5',

            // <ul>
            '[&_ul]:pl-4 [&_ul_li]:list-["—"] [&_ul_li]:marker:text-salmon-4',

            // <li>
            '[&_li]:mb-2 [&_li]:whitespace-pre-line [&_li]:ps-1.5',
          )}
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
      )}

      {product.selectedVariant.metafields.homeAssembly && (
        <div className="mt-4 flex flex-row items-center gap-2">
          <GoTools />
          {formatMessage({ id: 'tastics.product.content-manager.product_requires_home_assembly' })}
        </div>
      )}
    </section>
  );
}
