import { useContext } from 'react';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { FNCVariant } from 'shared/types/product/Product';
import Dropdown from '~/components/furniturechoice/dropdown';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { VariantOption, VariantOptionValue } from '../build-variant-options';

interface DropdownSelectorProps {
  option: VariantOption;
}

interface NoNullVariantOptionValue extends VariantOptionValue {
  variant: FNCVariant;
}

export default function DropdownSelector({ option }: DropdownSelectorProps): JSX.Element {
  const { navigateWithProgress } = useContext(ProgressIndicatorContext);
  const router = useRouter();
  const { locale } = useParams();
  const pathname = usePathname();
  const pathPrefix = pathname.split('/').slice(0, -2).join('/');
  const validOptionValues = option.values.filter(({ variant }) => variant !== null) as NoNullVariantOptionValue[];

  const dropdownOptions = validOptionValues.map(({ value, variant, isActive }) => ({
    label: (
      <div className="flex w-full gap-x-3">
        <span>{value}</span>
        <span className="font-semibold">
          {CurrencyHelpers.formatForCurrency(variant?.discountedPrice || variant?.price || 0, locale)}
        </span>
      </div>
    ),
    value: variant.sku,
    selected: isActive,
  }));

  function handleValueChange(sku: string) {
    const selectedOption = validOptionValues.find(({ variant }) => variant.sku === sku);

    if (selectedOption) {
      navigateWithProgress(() => router.push(`${pathPrefix}/${selectedOption.to}`));
    }
  }

  return <Dropdown label={option.label} options={dropdownOptions} onValueChange={handleValueChange} />;
}
