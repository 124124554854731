import { useRef, useId } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { FNCAsset } from 'shared/types/furniturechoice/asset';
import { SwiperContainer } from 'swiper/element/bundle';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import GenericImage from '~/components/furniturechoice/generic-image';
import useSwiper from '~/helpers/hooks/furniturechoice/use-swiper';
import useSwiperPhotoswipe from '~/helpers/hooks/furniturechoice/use-swiper-photoswipe';
import useVimeo from './use-vimeo';

interface MediaSliderProps {
  slides: FNCAsset[];
}

export default function MediaSlider({ slides }: MediaSliderProps): JSX.Element {
  const id = useId();
  const ref = useRef<SwiperContainer>(null);
  const { data: vimeoData } = useVimeo(slides.find((slide) => slide.source === 'vimeo')?.uri);
  useSwiper();
  useSwiperPhotoswipe(ref, id, vimeoData);

  return (
    <div id={id}>
      <swiper-container
        // @ts-ignore: React doesn't support className for web components
        class="mb-2.5 flex overflow-hidden lg:mb-6 lg:rounded-md lg:shadow"
        slides-per-view="1"
        centered-slides="true"
        thumbs-swiper="[data-pdp-thumbs-swiper]"
        ref={ref}
      >
        {slides.map((slide) => (
          <swiper-slide
            // @ts-ignore: React doesn't support className for web components
            class="flex aspect-square min-w-full items-center justify-center"
            data-testid="gallery-slide-image"
            key={slide.uri}
          >
            <Link
              className="block h-full w-full"
              href="#"
              data-pswp-src={slide.uri}
              data-pswp-width={slide.dimensions.width}
              data-pswp-height={slide.dimensions.height}
            >
              {slide.source === 'vimeo' ? (
                <>
                  <div className="absolute inset-0 z-10 flex items-center justify-center">
                    <div className="flex h-16 w-24 items-center justify-center rounded bg-black/50 text-32 text-white">
                      ►
                    </div>
                  </div>

                  <GenericImage
                    className="absolute h-full w-full rounded-[2.5px] bg-grey-1 object-cover"
                    src={vimeoData?.thumbnail_url?.replace(/(_\d+x\d+)/, '_1080x1080') || ''}
                    width={slide.dimensions.width}
                    height={slide.dimensions.height}
                    alt={slide.alt}
                    unoptimized
                  />
                </>
              ) : (
                <ContentfulImage
                  className="h-full w-full object-cover"
                  src={slide.uri}
                  alt={slide.alt}
                  colSpanPercentLg={0.54}
                />
              )}
            </Link>
          </swiper-slide>
        ))}
      </swiper-container>

      <swiper-container
        // @ts-ignore: React doesn't support className for web components
        class={clsx(
          'mx-2.5 flex h-[107px] justify-center overflow-x-hidden lg:h-[135px]',
          // eslint-disable-next-line prettier/prettier
          '[--swiper-navigation-color:black] [--swiper-navigation-sides-offset:5px] [--swiper-navigation-size:12px] [--swiper-navigation-top-offset:calc(100%-6px)]',
          // eslint-disable-next-line prettier/prettier
          '[--swiper-scrollbar-bg-color:#e6e6e6] [--swiper-scrollbar-drag-bg-color:black] [--swiper-scrollbar-sides-offset:22px] [--swiper-scrollbar-size:3px] [--swiper-scrollbar-top:calc(100%-7px)]',
          // eslint-disable-next-line prettier/prettier
          'lg:[--swiper-navigation-sides-offset:calc(22%-22px)] lg:[--swiper-scrollbar-sides-offset:22%]',
        )}
        data-testid="thumbs-swiper"
        data-pdp-thumbs-swiper
        slides-per-view="auto"
        center-insufficient-slides="true"
        scrollbar
        navigation
      >
        {slides.map((slide) => (
          <swiper-slide
            // @ts-ignore: React doesn't support className for web components
            class={clsx(
              'relative flex aspect-square h-[71px] w-[71px] min-w-[71px] items-center justify-center rounded border border-solid border-transparent p-[3px]',
              'lg:h-[92px] lg:w-[92px] lg:min-w-[92px] lg:border-2 lg:p-1',
              '[&.swiper-slide-thumb-active]:border-grey-7',
            )}
            data-testid="thumbs-slide-image"
            key={slide.uri}
          >
            {slide.source === 'vimeo' ? (
              <GenericImage
                className="aspect-square rounded-[2.5px] object-cover"
                src={vimeoData?.thumbnail_url_with_play_button || ''}
                width="90"
                height="90"
                alt={slide.alt}
                unoptimized
              />
            ) : (
              <ContentfulImage className="rounded-[2.5px] object-cover" src={slide.uri} sizes="90px" alt={slide.alt} />
            )}
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
}
