'use client';

import { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { MdCheck, MdVisibility } from 'react-icons/md';
import { FNCVariant, Product } from 'shared/types/product/Product';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { useCart } from '~/frontastic/hooks';
import { MinicartContext } from '~/helpers/contexts/furniturechoice/minicart';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';

interface SwatchSelectionProps {
  swatches: Product[];
  selectedVariant: FNCVariant;
  onClose: () => void;
  swatchSkusInCart: string[];
}

interface ActiveSwatch {
  sku: string;
  src: string;
  name: string;
}

export default function SwatchSelection({
  swatches,
  selectedVariant,
  onClose,
  swatchSkusInCart,
}: SwatchSelectionProps): JSX.Element {
  const [activeSwatches, setActiveSwatches] = useState<ActiveSwatch[]>([]);
  const { openMinicart } = useContext(MinicartContext);
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const cart = useCart();

  useEffect(() => {
    // Update activeSwatches based on swatchSkusInCart when modal opens
    const initialActiveSwatches = swatches
      .filter((swatch) => swatchSkusInCart.includes(swatch.variants[0].sku || ''))
      .map((swatch) => ({
        sku: swatch.variants[0].sku || '',
        src: swatch.variants[0].images ? swatch.variants[0].images[0] : '',
        name: swatch.name || '',
      }));
    setActiveSwatches(initialActiveSwatches);
  }, [swatches, swatchSkusInCart]);

  const handleSwatchClick = (sku: string, src: string, name: string) => {
    setActiveSwatches((prevActiveSwatches) => {
      if (prevActiveSwatches.some((swatch) => swatch.sku === sku)) {
        return prevActiveSwatches.filter((swatch) => swatch.sku !== sku);
      } else if (prevActiveSwatches.length < 5) {
        return [...prevActiveSwatches, { sku, src, name }];
      } else {
        return prevActiveSwatches;
      }
    });
  };

  async function addSwatchToCart() {
    const cartLineItemSkus = cart.data?.lineItems?.map((lineItem) => lineItem.variant.sku) || [];
    await executeWithProgress(async () => {
      for (const swatch of activeSwatches) {
        if (!cartLineItemSkus.includes(swatch.sku)) {
          await cart.addItem({ sku: swatch.sku }, 1);
        }
      }

      for (const sku of cartLineItemSkus) {
        if (!activeSwatches.some((swatch) => swatch.sku === sku)) {
          const lineItem = cart.data?.lineItems?.find((item) => item.variant.sku === sku);
          if (lineItem) {
            await cart.removeItem(lineItem.lineItemId as string);
          }
        }
      }
    });

    onClose();
    openMinicart();
  }

  return (
    <>
      <div className="my-6 grid grid-cols-3 gap-x-7 gap-y-4 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-6">
        {swatches.map((relatedSwatch, index) => {
          const sku = relatedSwatch.variants[0].sku || '';
          const src = relatedSwatch.variants[0].images ? relatedSwatch.variants[0].images[0] : '';
          const name = relatedSwatch.name || '';
          const isActive = activeSwatches.some((swatch) => swatch.sku === sku);
          const initialStyling = sku === selectedVariant.attributes?.related_swatch && !isActive;
          const { isOnStock } = relatedSwatch.variants[0];

          return (
            <div key={index} data-quantity={!isOnStock} className="w-full">
              <button
                type="button"
                onClick={() => handleSwatchClick(sku, src, name)}
                disabled={!isOnStock}
                className={clsx('relative', { 'opacity-50': !isOnStock })}
              >
                <div
                  className={clsx('relative aspect-square w-[74px] rounded-full border-2 p-0.5', {
                    'border-transparent': !isActive && !initialStyling,
                    'border-2 border-solid border-green-1': isActive,
                    'border-2 border-dotted border-green-1': initialStyling,
                  })}
                >
                  <ContentfulImage className="rounded-full" data-testid="swatch-image" src={src} sizes="70px" />

                  {initialStyling && (
                    <div className="absolute -left-1.5 -top-1.5 flex h-6 w-6 items-center justify-center rounded-full border-2 border-solid border-white bg-green-1 p-0.5">
                      <MdVisibility className="fill-white" />
                    </div>
                  )}
                  {isActive && (
                    <div className="absolute -left-1.5 -top-1.5 flex h-6 w-6 items-center justify-center rounded-full border-2 border-solid border-white bg-green-1 p-0.5">
                      <MdCheck className="fill-white" />
                    </div>
                  )}
                </div>

                <p className="mt-2 text-center text-12 text-grey-5">{name}</p>
              </button>
            </div>
          );
        })}
      </div>
      <div
        className={clsx('relative my-2 rounded border px-3 py-2', {
          'border-grey-3': activeSwatches.length === 0,
          'border-green-1': activeSwatches.length > 0,
        })}
      >
        <p className="mb-2 flex-none text-12">
          {activeSwatches.length === 0
            ? 'You can select up to 5 free swatches'
            : activeSwatches.length === 5
            ? 'You have selected all free swatches'
            : `You can select ${5 - activeSwatches.length} more free swatches`}
        </p>
        <div className="flex w-full flex-row justify-between">
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              className={clsx('aspect-square w-[34px] rounded-full lg:w-[45px]', {
                'border border-dotted border-grey-3': !activeSwatches[index],
              })}
            >
              {activeSwatches[index] && (
                <ContentfulImage
                  className="rounded-full"
                  src={activeSwatches[index].src}
                  sizes="50px"
                  alt={activeSwatches[index].name}
                />
              )}
            </div>
          ))}
        </div>
        {activeSwatches.length > 0 && (
          <div className="absolute -right-1.5 -top-1.5 flex h-5 w-5 items-center justify-center rounded-full border-2 border-solid border-white bg-green-1 p-0.5">
            <MdCheck className="fill-white" />
          </div>
        )}
      </div>
      <FNCButton
        className={clsx('mt-4 min-h-[48px] w-full text-white', {
          'surface-grey-3': activeSwatches.length === 0,
          'surface-green-2': activeSwatches.length > 0,
        })}
        disabled={activeSwatches.length === 0}
        onClick={addSwatchToCart}
      >
        {formatMessage({ id: 'tastics.product.content-manager.add_to_basket' })}
      </FNCButton>
    </>
  );
}
