'use client';

import { useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { FNCProduct } from 'shared/types/product/Product';
import Accordion from '~/components/furniturechoice/accordion';
import { useFormat } from '~/helpers/hooks/useFormat';
import DimensionTable from './dimension-table';
import PageDescription from './page-description';
import ReviewsTable from './review-table/ReviewsTable';
import ReviewWidget from './review-table/ReviewWidget';
import SpecificationTable from './specification-table';
import { PDPReferences } from '..';

interface ExtraDetailsProps {
  product: FNCProduct;
  references?: PDPReferences;
}

export default function ExtraDetails({ product, references }: ExtraDetailsProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const page = useRef(0);

  const reviewPanel = <ReviewsTable product={product} page={page} />;
  const descriptionPanel = <PageDescription product={product} />;
  const specsPanel = <SpecificationTable product={product} />;
  const dimensionsPanel = <DimensionTable product={product} />;

  const [reviewExpanded, setReviewExpanded] = useState(false);

  const reviewCallback = useCallback((status: boolean) => {
    setReviewExpanded(status);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash == '#review-ac') {
      if (references?.review.current instanceof HTMLButtonElement) {
        if (references?.review.current.ariaExpanded == 'false') {
          references?.review.current.click();
        }
      }
      references?.review.current?.scrollIntoView();
    }
  }, [references?.review]);

  const accordionData = [
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.product_description' }),
      panel: descriptionPanel,
      isExpanded: false,
    },
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.reviews' }),
      panel: reviewPanel,
      isExpanded: false,
      headingElem: (
        <div
          className={clsx('transition-opacity ', { 'opacity-100': !reviewExpanded }, { 'opacity-0': reviewExpanded })}
        >
          <ReviewWidget product={product} />
        </div>
      ),
      callback: reviewCallback,
      reference: references?.review,
      navigationId: 'review-ac',
    },
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.product_specification' }),
      panel: specsPanel,
      isExpanded: false,
    },
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.product_dimensions' }),
      panel: dimensionsPanel,
      isExpanded: false,
    },
  ];

  return (
    <div className="container">
      <Accordion className="mt-10 border-t border-grey-3" contents={accordionData} isOnDemandPanel />
    </div>
  );
}
