import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { addDays } from 'date-fns';
import ReactCalendar from 'react-calendar';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';
import isTileDisabled from './is-tile-disabled';

interface DXCalendarProps {
  accountNumber: string;
  defaultValue?: string;
  postcode?: string;
  isSelectable?: boolean;
  onDeliveryDateChange(date: Date | null): void;
}

export default function DXCalendar({
  accountNumber,
  defaultValue,
  postcode,
  isSelectable,
  onDeliveryDateChange,
}: DXCalendarProps): JSX.Element {
  const [allowedDates, setAllowedDates] = useState<string[]>([]);
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  useEffect(() => {
    async function getAllowedDates() {
      const res = await executeWithProgress(() =>
        sdk.callAction<{ dates: string[] }>({
          actionName: 'furniturechoice-shipping-calendar/getDXCalendar',
          payload: { accountNumber, postcode },
        }),
      );

      if (!res.isError) {
        setAllowedDates(res.data.dates);
      }
    }

    getAllowedDates();
  }, [accountNumber, executeWithProgress, postcode]);

  return (
    <>
      {allowedDates && (
        <ReactCalendar
          className={clsx(
            'surface-white rounded-sm border border-solid border-grey-3 text-center',
            String.raw`[&_abbr]:no-underline`,
            String.raw`[&_.react-calendar\_\_navigation]:flex [&_.react-calendar\_\_navigation]:items-center [&_.react-calendar\_\_navigation]:justify-between [&_.react-calendar\_\_navigation]:px-3 lg:[&_.react-calendar\_\_navigation]:px-10`,
            String.raw`[&_.react-calendar\_\_navigation\_\_next2-button]:hidden [&_.react-calendar\_\_navigation\_\_prev2-button]:hidden`,
            String.raw`[&_.react-calendar\_\_navigation\_\_arrow]:h-10 [&_.react-calendar\_\_navigation\_\_arrow]:w-10 [&_.react-calendar\_\_navigation\_\_arrow]:text-20 [&_.react-calendar\_\_navigation\_\_arrow]:font-semibold disabled:[&_.react-calendar\_\_navigation\_\_arrow]:text-grey-3`,
            String.raw`[&_.react-calendar\_\_navigation\_\_label]:h-10 [&_.react-calendar\_\_navigation\_\_label]:font-semibold`,
            String.raw`[&_.react-calendar\_\_viewContainer]:px-3 [&_.react-calendar\_\_viewContainer]:pb-3 lg:[&_.react-calendar\_\_viewContainer]:px-10`,
            String.raw`[&_.react-calendar\_\_month-view]:border-t [&_.react-calendar\_\_month-view]:border-solid [&_.react-calendar\_\_month-view]:border-t-grey-3 [&_.react-calendar\_\_month-view]:pt-3`,
            String.raw`[&_.react-calendar\_\_month-view\_\_weekdays]:!grid [&_.react-calendar\_\_month-view\_\_weekdays]:grid-cols-7 [&_.react-calendar\_\_month-view\_\_weekdays]:justify-center [&_.react-calendar\_\_month-view\_\_weekdays]:gap-2 [&_.react-calendar\_\_month-view\_\_weekdays]:text-14 [&_.react-calendar\_\_month-view\_\_weekdays]:font-medium`,
            String.raw`[&_.react-calendar\_\_month-view\_\_weekdays\_\_weekday]:justify-self-center`,
            String.raw`[&_.react-calendar\_\_month-view\_\_days]:!grid [&_.react-calendar\_\_month-view\_\_days]:grid-cols-7 [&_.react-calendar\_\_month-view\_\_days]:justify-center [&_.react-calendar\_\_month-view\_\_days]:gap-2 [&_.react-calendar\_\_month-view\_\_days]:pt-4`,
          )}
          tileClassName={clsx(
            'h-8 max-h-[32px] w-8 max-w-[32px] justify-self-center rounded-full border border-solid border-green-2 text-14',
            'disabled:border-transparent disabled:bg-grey-2 disabled:text-grey-3',
            String.raw`[&.react-calendar\_\_tile--active]:surface-green-2`,
            { 'surface-green-2': !isSelectable },
          )}
          minDate={new Date()}
          maxDate={addDays(new Date(), 45)}
          minDetail="month"
          calendarType="gregory"
          defaultValue={defaultValue}
          tileDisabled={({ date }) => isTileDisabled(date, allowedDates)}
          onChange={(value) => onDeliveryDateChange(value as Date | null)}
        />
      )}
    </>
  );
}
