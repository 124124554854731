import Image from '~/frontastic/lib/image';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function NoReviews() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="text-center">
      <Image
        className="mx-auto inline-block w-1/2 md:w-1/3"
        style={{ aspectRatio: '42/41' }}
        src="/images/no-reviews-icon.png"
        alt="no review"
      />
      <div className="mt-3 text-center text-16 font-normal text-grey-4 md:text-18">
        {formatMessage({ id: 'tastics.review.first_review' })}
      </div>
    </div>
  );
}
