import { useContext } from 'react';
import { FNCProductDimension } from 'shared/types/product/Product';
import useSWR, { SWRResponse } from 'swr';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';

export default function useProductDimensions(productId: string): SWRResponse<FNCProductDimension[]> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  return useSWR<FNCProductDimension[]>(`/furniturechoice-product/getDimensions/${productId}`, async () => {
    const response = await executeWithProgress(() =>
      sdk.callAction<FNCProductDimension[]>({
        actionName: 'furniturechoice-product/getDimensions',
        payload: { productId },
      }),
    );

    if (response.isError) {
      throw response.error;
    }

    return response.data;
  });
}
