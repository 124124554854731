'use client';

import { MutableRefObject, useEffect, useRef } from 'react';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import { FNCProduct } from 'shared/types/product/Product';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';
import ExtraDetails from './extra-details';
import MainDetails from './main-details';
import ShopTheCollection from './shop-the-collection';

interface ContentManagerProps {
  product: DataSourceV2<FNCProduct>;
  financeTerms: DataSourceV2<FinanceProduct[]>;
  onDeliveryDateChange(date: Date | null): void;
}

export interface PDPReferences {
  review: MutableRefObject<HTMLButtonElement | null>;
}

function storeAsRecentlyViewedProduct(product: FNCProduct): void {
  const historyLimit = 25;
  const currSKU = product.selectedVariant.sku;
  const recentSKUs = JSON.parse(localStorage.getItem('recentSKUs') || '[]') as string[];

  if (recentSKUs && !recentSKUs.includes(currSKU)) {
    if (recentSKUs.length >= historyLimit) {
      recentSKUs.shift();
    }

    recentSKUs.push(currSKU);
    localStorage.setItem('recentSKUs', JSON.stringify(recentSKUs));
  }
}

export default function ContentManager({
  data: { product, financeTerms, onDeliveryDateChange },
}: TasticProps<ContentManagerProps>) {
  useEffect(() => {
    storeAsRecentlyViewedProduct(product.dataSource);
  }, [product.dataSource]);

  const references = {
    review: useRef<HTMLButtonElement>(null),
  };

  return (
    <section className="mt-8">
      <MainDetails
        product={product.dataSource}
        financeTerms={financeTerms.dataSource}
        onDeliveryDateChange={onDeliveryDateChange}
        references={references}
      />
      <ExtraDetails product={product.dataSource} references={references} />
      <ShopTheCollection product={product.dataSource} />
    </section>
  );
}
