import { MdChevronRight } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import ReviewStars from '~/components/furniturechoice/review/ReviewStars';
import { ReviewSummaryHelper } from '~/helpers/furniturechoice/review/review-summary';
import { useFormat } from '~/helpers/hooks/useFormat';

interface ReviewWidgetProps {
  product: FNCProduct;
  reviewRef?: HTMLButtonElement | null;
}

export default function ReviewWidgetHeader({ product, reviewRef }: ReviewWidgetProps) {
  const stats = product.reviewRatingStatistics;
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const clickHandler = () => {
    if (reviewRef instanceof HTMLButtonElement) {
      if (reviewRef.ariaExpanded == 'false') {
        reviewRef.click();
      }
      reviewRef.scrollIntoView();
    }
  };

  if (!stats || !stats.count) return <></>;

  return (
    <button onClick={clickHandler}>
      <div className="mt-1 flex items-center gap-1 text-left font-sans">
        <ReviewStars stars={stats.averageRating} className="fill-grey-5 text-16" />
        <span className="inline-block text-16 font-semibold leading-6">
          {ReviewSummaryHelper.getAverageRating(stats)}
        </span>
        <span className="pl-2 text-12 font-normal text-salmon-1 underline">
          {formatMessage(ReviewSummaryHelper.getReviewCount(stats))}
          <MdChevronRight className="inline" />
        </span>
      </div>
    </button>
  );
}
