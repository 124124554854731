import { FNCProduct } from 'shared/types/product/Product';
import TableItem from './table-item';

interface DimensionTableProps {
  product: FNCProduct;
}

export default function DimensionTable({ product }: DimensionTableProps): JSX.Element {
  const bundles = product.bundles && product.bundles.length > 0 ? product.bundles : [{ product }];

  return (
    <div className="flex flex-col gap-y-[68px] lg:gap-y-24">
      {bundles.map((bundle) => (
        <TableItem key={bundle.product.productId} product={bundle.product} />
      ))}
    </div>
  );
}
