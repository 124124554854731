type LocalizedString = Record<string, string>;
type Enum = { key: string; label: string };
type LocalizedEnum = { key: string; label: LocalizedString };
type LocaleValue = LocalizedString | LocalizedEnum | unknown;
type Value = Enum | unknown;

export function getProductTypeAttributeLocaleValue(value: LocaleValue, locale: string): string | unknown {
  if (typeof value === 'object' && value !== null) {
    if ('label' in value) {
      const label = (value as Enum | LocalizedEnum).label;

      if (typeof label === 'string') {
        return label;
      } else if (typeof label === 'object') {
        return locale in label ? label[locale] : '';
      }
    }

    return locale in value ? (value as LocalizedString)[locale] : '';
  }

  return value;
}

export function getProductTypeAttributeValue(value: Value): string | unknown {
  if (typeof value === 'object' && value !== null) {
    if ('label' in value) {
      return (value as Enum).label;
    }
  }

  return value;
}
