import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import DynamicTag from '../dynamic-tag';

interface SectionHeaderProps extends HTMLAttributes<HTMLDivElement> {
  as: string;
}

export default function SectionHeader({ children, className, as = 'h2', ...props }: SectionHeaderProps): JSX.Element {
  return (
    <DynamicTag
      as={as}
      className={twMerge(
        clsx(
          'flex flex-col items-center gap-y-2 text-center text-32 lg:text-48',
          'after:block after:h-px after:w-[42px] after:bg-salmon-1 after:content-[""] lg:after:h-0.5 lg:after:w-[60px]',
          className,
        ),
      )}
      {...props}
    >
      {children}
    </DynamicTag>
  );
}
