import { format } from 'date-fns';
import Review from 'shared/types/review/Review';
import Link from '~/components/furniturechoice/link';
import ReviewStars from '~/components/furniturechoice/review/ReviewStars';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function ReviewItem({ review }: { review: Review }) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div role="comment" className="grid w-full gap-4 py-8">
      <div className="flex flex-row">
        <div className="relative mr-2 flex h-10 w-10 flex-wrap content-center rounded-full bg-salmon-1">
          <span className="absolute top-1/2 w-full -translate-y-1/2 text-center text-white">
            {review.authorName.substring(0, 1).toUpperCase()}
          </span>
        </div>
        <div className="relative flex-auto">
          <div className="font-semibold leading-6 text-grey-6">{review.authorName}</div>
          <div className="text-12 leading-4 text-grey-5">{format(review.date, 'dd LLL yyyy')}</div>
        </div>
      </div>

      <div className="flex flex-row gap-2 md:gap-x-12">
        <div className="grid w-1/3 gap-1 text-14 md:w-auto" title={`Star rating: ${review.ratingOverall}`}>
          <div>{formatMessage({ id: 'tastics.review.overall', defaultMessage: 'Overall' })}</div>
          <div>
            <ReviewStars stars={review.ratingOverall} className="fill-salmon-1 text-14" justify="left" />
          </div>
        </div>
        <div className="grid w-1/3 gap-1 text-14 md:w-auto" title={`Star rating: ${review.ratingPrice}`}>
          <div>{formatMessage({ id: 'tastics.review.price', defaultMessage: 'Price' })}</div>
          <div>
            <ReviewStars stars={review.ratingPrice} className="fill-salmon-1 text-14" justify="left" />
          </div>
        </div>
        <div className="grid w-1/3 gap-1 text-14 md:w-auto" title={`Star rating: ${review.ratingQuality}`}>
          <div>{formatMessage({ id: 'tastics.review.quality', defaultMessage: 'Quality' })}</div>
          <div>
            <ReviewStars stars={review.ratingQuality} className="fill-salmon-1 text-14" justify="left" />
          </div>
        </div>
      </div>
      <div
        data-product-code={review.product.sku}
        className="inline-block w-full rounded bg-grey-1 p-4 font-normal text-grey-6"
      >
        <Link href={review.product.url}>{review.product.name}</Link>
      </div>
      <div className="w-full">
        <div className="font-normal leading-5 text-grey-5">{review.text}</div>
      </div>
      {review.fcresponse && (
        <div className="ml-4 whitespace-pre-line rounded bg-grey-1 p-4 font-normal leading-5 text-grey-5">
          {review.fcresponse.trim()}
        </div>
      )}
    </div>
  );
}
