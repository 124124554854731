import { type ChangeEvent, forwardRef, type ForwardedRef, useId, useState, TextareaHTMLAttributes } from 'react';
import clsx from 'clsx';
import debounce from 'lodash.debounce';

interface FNCTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  isAnimatingLabelDisabled?: boolean;
  supportingText?: string;
  patternFeedback?: string;
  inputClassName?: string;
  onEveryChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onValidChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
}

function FNCTextArea(
  {
    className,
    label,
    isAnimatingLabelDisabled,
    supportingText,
    patternFeedback,
    defaultValue,
    inputClassName,
    onEveryChange,
    onValidChange,
    ...props
  }: FNCTextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
): JSX.Element {
  const [hasValue, setHasValue] = useState<boolean>(!!defaultValue || !!props.value);
  const [feedback, setFeedback] = useState<string>('');
  const [validationStyle, setValidationStyle] = useState<'passive' | 'aggressive'>('passive');
  const id = useId();

  function getValidationFeedback(element: HTMLTextAreaElement): string {
    if (element && !element.checkValidity()) {
      return element.validity.patternMismatch ? patternFeedback || '' : element.validationMessage;
    }

    return '';
  }

  function handleDebouncedChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    const validationFeedback = getValidationFeedback(event.target);

    setFeedback(validationFeedback);

    if (!validationFeedback || event.target.value === '') {
      onValidChange?.(event);
    }
  }

  const debouncedChange = debounce(handleDebouncedChange, 400);

  function handleChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    setHasValue(!!event.target.value);
    onEveryChange?.(event);
    debouncedChange(event);
  }

  function handleBlur(): void {
    if (feedback && validationStyle === 'passive') {
      setValidationStyle('aggressive');
    }
  }

  return (
    <div className={clsx('flex flex-col gap-2', { 'text-red-1': feedback !== '' }, className)}>
      <div className="relative flex h-[200px] flex-col gap-y-1 bg-transparent">
        <textarea
          className={clsx(
            'surface-white disabled:surface-grey-2 peer h-[200px] w-full rounded-md border border-solid px-3 pb-1 pt-6 text-16',
            inputClassName,
            {
              'border-grey-7': feedback === '',
              '[&:not(:focus-visible)]:border-red-1': feedback !== '',
            },
          )}
          id={id}
          defaultValue={defaultValue}
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          ref={ref}
        />

        <label
          className={clsx(
            'absolute left-3 top-[28px] text-grey-7 transition-[font-size,transform] duration-standard ease-standard',
            'peer-focus-visible:translate-y-[-20px] peer-focus-visible:text-12',
            {
              'translate-y-[-20px] text-12': hasValue || isAnimatingLabelDisabled,
              '-translate-y-1/2 text-16': !hasValue && !isAnimatingLabelDisabled,
            },
          )}
          htmlFor={id}
          data-testid="label"
        >
          {label}
        </label>
      </div>

      {supportingText && !feedback && (
        <div className="text-14 leading-[18px]" data-testid="supporting-text">
          {supportingText}
        </div>
      )}

      {feedback && validationStyle === 'aggressive' && (
        <div className="text-14 leading-[18px]" role="alert" data-testid="feedback">
          {feedback}
        </div>
      )}
    </div>
  );
}

export default forwardRef<HTMLTextAreaElement, FNCTextAreaProps>(FNCTextArea);
