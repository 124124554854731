import { MdOutlineStarBorder } from 'react-icons/md';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function WriteReviewSent() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="mt-8 grid gap-4 text-center">
      <div>
        <MdOutlineStarBorder className="m-auto text-48" />
      </div>
      <div className="font-normal text-grey-5">{formatMessage({ id: 'tastics.review.review_sent' })}</div>
    </div>
  );
}
