'use client';

import React, { useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types/dist/types';
import { MdCheck, MdChevronRight } from 'react-icons/md';
import { USPAttributes, USPFinanceAttributes } from 'shared/types/product/USP';
import Guarantee, { processGuaranteeTitle } from '~/components/furniturechoice/rich-text/contentful/page/usp/guarantee';
import USPContent from '~/components/furniturechoice/rich-text/contentful/page/usp/usp-content';
import ZeroFinance from '~/components/furniturechoice/rich-text/contentful/page/usp/zero-finance';
import SideModal from '~/components/furniturechoice/side-modal';
import { ContentfulUSPType } from '~/types/contentful/USP';

// Minimum financing amount in pennies
export const FINANCE_MIN_AMT = 35000;

type ClickHandler = () => void;

type GA4Params = {
  ga4_event_name: string;
  ga4_event_param_1_name: string;
  ga4_event_param_1_value: string;
};

interface iUSPAttr {
  title: string;
  alt: string;
  ga4params: GA4Params;
  onClick: ClickHandler;
  show: boolean;
  isOpen: boolean;
  modal: JSX.Element;
}

/**
 * Used to create a blank USP item in case a blank or null had been passed in
 */
function createBlankUspItem(): USPAttributes {
  return {
    title: '',
    label: '',
    type: '' as unknown as ContentfulUSPType,
    text: { nodeType: 'document' as unknown as BLOCKS.DOCUMENT, content: [], data: [] },
  };
}

/**
 * Determines if product is eligible for financing based
 * on whether product price meets minimum financing amount
 */
function getFinancing(amount: number) {
  return amount >= FINANCE_MIN_AMT;
}

interface USPElementProps {
  aboutUsData: USPAttributes;
  freeDeliveryData: USPAttributes;
  guaranteeData: USPAttributes;
  guaranteeDuration: string;
  financeData: USPFinanceAttributes;
  productPrice: number;
  returnsData: USPAttributes;
}

export default function USP({
  aboutUsData,
  freeDeliveryData,
  guaranteeData,
  guaranteeDuration,
  financeData,
  productPrice,
  returnsData,
}: USPElementProps): JSX.Element {
  // Set some defaults in case invalid data was passed in
  if (!guaranteeDuration) {
    guaranteeDuration = '1-YEAR';
  }
  if (!aboutUsData) {
    aboutUsData = createBlankUspItem();
  }
  if (!freeDeliveryData) {
    freeDeliveryData = createBlankUspItem();
  }
  if (!guaranteeData) {
    guaranteeData = createBlankUspItem();
  }
  if (!financeData) {
    const blankUsp = createBlankUspItem();
    const blankFinanceUsp = {
      financeProducts: [],
      financeModalOpen: false,
      currencyCode: '',
      onClick: () => {},
      ...blankUsp,
    };
    financeData = blankFinanceUsp;
  }
  if (!returnsData) {
    returnsData = createBlankUspItem();
  }

  // Watch for changes when any USP item is clicked
  const [openFreeDelivery, setOpenFreeDel] = useState(false);
  const [openFreeReturns, setOpenFreeReturns] = useState(false);
  // const [openZeroFinance, setOpenZeroFinance] = useState(false);
  const [openGuarantee, setOpenGuarantee] = useState(false);
  const [openFamilyOwned, setOpenFamilyOwned] = useState(false);

  // process text replacement for Guarantee
  const guaranteeLabel = processGuaranteeTitle(
    guaranteeData.label || guaranteeData.title,
    guaranteeDuration.toLowerCase(),
  );

  // set attributes for all USP items
  const USPAttrs: iUSPAttr[] = [
    {
      title: freeDeliveryData.label || freeDeliveryData.title,
      alt: 'free_delivery',
      ga4params: {
        ga4_event_name: 'usp_click',
        ga4_event_param_1_name: 'element',
        ga4_event_param_1_value: freeDeliveryData.type,
      },
      show: freeDeliveryData.title ? true : false,
      isOpen: openFreeDelivery,
      onClick: () => {
        setOpenFreeDel(!openFreeDelivery);
      },
      modal: <USPContent data={freeDeliveryData} />,
    },
    {
      title: returnsData.label || returnsData.title,
      alt: 'free_returns',
      ga4params: {
        ga4_event_name: 'usp_click',
        ga4_event_param_1_name: 'element',
        ga4_event_param_1_value: returnsData.type,
      },
      show: returnsData.title ? true : false,
      isOpen: openFreeReturns,
      onClick: () => {
        setOpenFreeReturns(!openFreeReturns);
      },
      modal: <USPContent data={returnsData} />,
    },
    {
      title: financeData.label || financeData.title,
      alt: 'zero_finance',
      ga4params: {
        ga4_event_name: 'usp_click',
        ga4_event_param_1_name: 'element',
        ga4_event_param_1_value: financeData.type,
      },
      show: getFinancing(productPrice),
      isOpen: financeData.financeModalOpen,
      onClick: () => {
        financeData.onClick();
      },
      modal: (
        <ZeroFinance
          data={financeData}
          amount={productPrice}
          currencyCode={financeData.currencyCode}
          financeTerms={financeData.financeProducts}
        />
      ),
    },
    {
      title: guaranteeLabel,
      alt: 'guarantee',
      ga4params: {
        ga4_event_name: 'usp_click',
        ga4_event_param_1_name: 'element',
        ga4_event_param_1_value: guaranteeData.type,
      },
      show: guaranteeData.title ? true : false,
      isOpen: openGuarantee,
      onClick: () => {
        setOpenGuarantee(!openGuarantee);
      },
      modal: <Guarantee data={guaranteeData} duration={guaranteeDuration} />,
    },
    {
      title: aboutUsData.label || aboutUsData.title,
      alt: 'family_owned',
      ga4params: {
        ga4_event_name: 'usp_click',
        ga4_event_param_1_name: 'element',
        ga4_event_param_1_value: aboutUsData.type,
      },
      show: aboutUsData.title ? true : false,
      isOpen: openFamilyOwned,
      onClick: () => {
        setOpenFamilyOwned(!openFamilyOwned);
      },
      modal: <USPContent data={aboutUsData} />,
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
        {USPAttrs.filter((attr) => attr.show).map((attr) => (
          <button
            id={'usp-list-item-' + attr.alt}
            type="button"
            key={attr.alt}
            className="text-left text-grey-6"
            onClick={attr.onClick}
            {...attr.ga4params}
          >
            <div className="flex leading-5">
              <MdCheck className="mr-2 w-[22px] fill-green-1 text-22" />
              <span className="text-14">{attr.title}</span>
              <MdChevronRight className="w-[22px] fill-grey-7 text-20" />
            </div>
          </button>
        ))}
      </div>
      {USPAttrs.filter((attr) => attr.show).map((attr) => (
        <SideModal key={attr.alt} isOpen={attr.isOpen} onModalClose={attr.onClick}>
          {attr.modal}
        </SideModal>
      ))}
    </>
  );
}
