import useReview from '~/frontastic/hooks/useReview';
import ReviewList from './ReviewList';
import ShowBlankReview from './ShowBlankReview';

export default function FetchReviews({
  targetId,
  page,
  rows,
  callback,
}: {
  targetId: string;
  page: number;
  rows: number;
  callback: (i: number, c: number) => void;
}) {
  const { data, error, isLoading } = useReview(targetId, page, rows);

  if (error) return <></>;
  if (isLoading) return <ShowBlankReview />;
  if (!data || data?.isError) return <></>;

  callback(page, data.data.length);

  return <ReviewList reviews={data.data} />;
}
