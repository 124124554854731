import { MutableRefObject } from 'react';
import { FNCProduct } from 'shared/types/product/Product';
import NoReviews from './NoReviews';
import ReviewManager from './ReviewManager';
import ReviewSummary from './ReviewSummary';
import WriteReviewOpener from './WriteReviewOpener';

const DEFAULT_ROW_SIZE = 6;

export default function ReviewsTable({ product, page }: { product: FNCProduct; page: MutableRefObject<number> }) {
  const baseIdReview = product.selectedVariant.attributes?.baseid_review;
  const stats = product.reviewRatingStatistics;

  return (
    <div className="lg:w-4/5 xl:w-3/5">
      <WriteReviewOpener product={product} />
      {stats && stats.count ? (
        <>
          <ReviewSummary stats={stats} />
          <ReviewManager targetId={baseIdReview} totalReviews={stats.count} page={page} rows={DEFAULT_ROW_SIZE} />
        </>
      ) : (
        <NoReviews />
      )}
    </div>
  );
}
