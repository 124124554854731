import { FNCProduct } from 'shared/types/product/Product';
import ProductCard from '../product-card';
import ViewCollectionCard from '../view-collection-card';

interface ProductCardGridProps {
  products: FNCProduct[];
  collectionUrl: string;
}

export default function ProductCardGrid({ products, collectionUrl }: ProductCardGridProps): JSX.Element {
  return (
    <div data-testid="product-card-grid" className="mt-16 grid auto-rows-fr grid-cols-2 gap-x-4 gap-y-8 lg:hidden">
      {products &&
        products.map((v) => (
          <ProductCard key={v.selectedVariant.sku} variant={v.selectedVariant} imageColSpanPercent={0.5} />
        ))}

      <ViewCollectionCard collectionUrl={collectionUrl} />
    </div>
  );
}
