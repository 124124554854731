import { HTMLAttributes } from 'react';
import { MdOutlineStar } from 'react-icons/md';
import { FNCReviewRatingStatistics } from 'shared/types/furniturechoice/product';
import ReviewStars from '~/components/furniturechoice/review/ReviewStars';
import { ReviewSummaryHelper } from '~/helpers/furniturechoice/review/review-summary';
import { useFormat } from '~/helpers/hooks/useFormat';

interface ReviewSummaryProps extends HTMLAttributes<HTMLDivElement> {
  stats: FNCReviewRatingStatistics;
}

export default function ReviewSummary({ stats, ...props }: ReviewSummaryProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div
      data-total-reviews={stats.count}
      data-average-rating={stats.averageRating}
      className="grid w-full grid-cols-1 gap-x-4 gap-y-8 px-4 py-16 text-center lg:grid-cols-2"
      {...props}
    >
      <div>
        <div className="text-48 font-bold">{ReviewSummaryHelper.getAverageRating(stats)}</div>
        <div className="my-2">
          <ReviewStars stars={stats.averageRating} className="fill-grey-5 text-24" />
        </div>
        <div className="mt-1 font-normal">{formatMessage(ReviewSummaryHelper.getReviewCount(stats))}</div>
      </div>
      <div className="mx-auto grid w-full max-w-[400px] gap-3">
        <StarDistributionLine stars={5} count={stats.ratingsDistribution['5'] ?? 0} total={stats.count} />
        <StarDistributionLine stars={4} count={stats.ratingsDistribution['4'] ?? 0} total={stats.count} />
        <StarDistributionLine stars={3} count={stats.ratingsDistribution['3'] ?? 0} total={stats.count} />
        <StarDistributionLine stars={2} count={stats.ratingsDistribution['2'] ?? 0} total={stats.count} />
        <StarDistributionLine stars={1} count={stats.ratingsDistribution['1'] ?? 0} total={stats.count} />
      </div>
    </div>
  );
}

function StarDistributionLine({ stars, count, total }: { stars: number; count: number; total: number }) {
  return (
    <div className="grid grid-cols-[16px_16px_1fr_40px] items-center justify-center gap-1">
      <div className="font-bold text-grey-6">{stars}</div>
      <div className="inline-block">
        <MdOutlineStar className="block fill-salmon-1 text-18" />
      </div>
      <div className="mx-1 w-full">
        <div
          className="m-auto inline-block h-2 w-full rounded-full align-middle"
          style={{ backgroundImage: 'linear-gradient(90deg, #ea9b7e ' + (count / total) * 100 + '%, #cccccc 0%)' }}
        ></div>
      </div>
      <div className="ml-2 text-left">({count})</div>
    </div>
  );
}
