export default function ShowBlankReview() {
  return (
    <div className="w-full py-8">
      <div className="flex flex-row">
        <div className="relative mr-2 flex h-10 w-10 animate-pulse flex-wrap rounded-full bg-grey-2"></div>
        <div className="relative flex-auto">
          <div className="mt-1 h-4 w-[180px] animate-pulse rounded bg-grey-2"></div>
          <div className="mt-2 h-3 w-[80px] animate-pulse rounded bg-grey-2"></div>
        </div>
      </div>
      <div className="my-2 mt-4 flex flex-wrap">
        <div className="w-1/3">
          <div className="h-3 w-[80px] animate-pulse rounded bg-grey-2"></div>
          <div className="mt-1 h-3 w-[100px] animate-pulse rounded bg-grey-2"></div>
        </div>
        <div className="w-1/3">
          <div className="h-3 w-[80px] animate-pulse rounded bg-grey-2"></div>
          <div className="mt-1 h-3 w-[100px] animate-pulse rounded bg-grey-2"></div>
        </div>
        <div className="w-1/3">
          <div className="h-3 w-[80px] animate-pulse rounded bg-grey-2"></div>
          <div className="mt-1 h-3 w-[100px] animate-pulse rounded bg-grey-2"></div>
        </div>
      </div>
      <div className="my-4 h-16 w-full animate-pulse rounded bg-grey-1"></div>
      <div className="my-4 h-32 w-full animate-pulse rounded bg-grey-2"></div>
    </div>
  );
}
